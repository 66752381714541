@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Julius+Sans+One&display=swap");

@font-face {
  font-family: Niladri;
  src: url("../fonts/Li\ Niladri\ Rongtuli\ Unicode.ttf") format("truetype");
}

$theme-color: #c5e1a5;
$body-color: #f8ffd7;
$bkash-theme: #d62267;
$bkash-theme-pink: #d62267;
$bkash-bg-color: #ffcdd2;

$nagad-bg-color: #fff2cc;
$nagad-theme: #f24f41;
$nagad-theme-red: #f24f41;
$nagad-theme-orange: #f58220;

$upay-bg-color: #ffd602;
$upay-theme: #024697;
$upay-theme-blue: #024697;

$rocket-bg-color: #dbbedd;
$rocket-theme: #89288f;
$rocket-theme-blue: #024697;

$heading-font: "Archivo Narrow", sans-serif;
$body-font-custom: "Julius Sans One", sans-serif;
$body-font: "Arial", sans-serif;
$bangla-font: Niladri, sans-serif;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  // background: $body-color;
  font-family: $body-font;
  line-height: 1.3;
  display: grid;
  justify-content: center;
  color: #333;
  font-weight: 300;
}

h1 {
  margin: 0;
}

p,
li,
h2,
h3 {
  margin-bottom: 0.5rem;
}

ul,
ol {
  margin-inline-start: 20px;
}

img {
  max-width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  & a {
    color: #333;
  }

  & h1 {
    margin: 0;
  }

  & select {
    border-radius: 3px;
    font-family: Arial, sans-serif;
    // height: 1.3rem;
  }
}

.menu {
  display: flex;
  font-family: $bangla-font;

  &__item {
    padding: 10px 15px;
    background: $nagad-bg-color;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    margin-right: 5px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
  }
}

.menu.menu--bn {
  font-size: 1.3rem;
}

.app {
  width: 600px;
}

.shell {
  background: $theme-color;
  border-radius: 0 7px 7px 7px;
  padding: 30px;
}

.home__icons {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  text-align: center;
  
}

.home__icons a {
  text-decoration: none;
  color: #333;
}

.home__icons img {
  border-radius: 25%;
}

.app.bkash--theme {
}

.shell.bkash--theme,
.menu__item.bkash--theme {
  background: $bkash-bg-color;
}

.shell.nagad--theme {
  background-color: $nagad-bg-color;
}
.shell.upay--theme {
  background-color: $upay-bg-color;
}
.shell.rocket--theme {
  background-color: $rocket-bg-color;
}

.bkash--theme {
  & h1,
  h2,
  h3 {
    color: $bkash-theme;
  }
}

.nagad--theme {
  & h1,
  h2,
  h3 {
    color: $nagad-theme;
  }
}
.rocket--theme {
  & h1,
  h2,
  h3 {
    color: $rocket-theme;
  }
}
.upay--theme {
  & h1,
  h2,
  h3 {
    color: $upay-theme;
  }
}

.menu__item.home--theme {
  background: $theme-color;
}

.menu__item.bkash--theme {
  color: $bkash-theme-pink;
}
.menu__item.nagad--theme {
  color: $nagad-theme-red;
}
.menu__item.rocket--theme {
  background: $rocket-bg-color;
  color: $rocket-theme;
}
.menu__item.upay--theme {
  background: $upay-bg-color;
  color: $upay-theme-blue;
}

h1,
h2,
h3 {
  font-family: $bangla-font;
  font-weight: normal;
}

.en--font {
  font-weight: bold;
}

.footer {
  font-size: 0.8rem;
  text-align: center;
  margin: 10px 0;
}

@media (max-width: 600px) {
  .header {
    padding: 0 10px;

    & select {
      padding: 0.4rem;
    }
  }

  select {
    font-size: 1.2rem;
  }

  .app {
    width: 100%;
  }

  .shell {
    padding: 20px 10px;
  }

  h1 {
    font-size: 1.5rem;
  }
}
